export interface IScanModeMetaData {
  id: string;
  preselectName: EScanModePreselectName;
  title: string;
  description: string;
  content: IContent[];
  scanMode: EScanModeTypes;
  active: boolean;
  data: Array<{
    img1: string;
    img2: string;
    img3: string;
  }>;
  useCase: string;
  icon?: any;
}

export interface IContent {
  type: EContentTypes;
  text: string | string[];
}

export enum EContentTypes {
  ShortDescription = 'short_description',
  Benefits = 'benefits',
  Features = 'features',
}

export enum EScanModeTypes {
  AUTO_ANALOG_DIGITAL_METER = 'AUTO_ANALOG_DIGITAL_METER',
  ANALOG_DIGITAL_SNR_BAR = 'ANALOG_DIGITAL_SNR_BAR',
  ID = 'ID',
  UNIVERSAL_ID = 'UNIVERSAL_ID',
  TSW = 'TSW',
  BARCODE = 'BARCODE',
  METER_EMEA = 'METER_EMEA',
  METER_EMEA_BARCODE = 'METER_EMEA_BARCODE',
}

export enum EScanModePreselectName {
  AUTO_ANALOG_DIGITAL_METER = 'meters',
  ANALOG_DIGITAL_SNR_BAR = 'meteridentification',
  ID = 'mrz',
  UNIVERSAL_ID = 'id',
  TSW = 'tiresidewall',
  BARCODE = 'barcode',
  METER_EMEA = 'meter_emea',
  METER_EMEA_BARCODE = 'meter_emea_barcode',
}

const scanModesMetaData: IScanModeMetaData[] = [
  {
    id: '5',
    preselectName: EScanModePreselectName.TSW,
    title: 'Tire Sidewall',
    useCase: 'For the Automotive Aftermarket',
    description: '(Tire Make, Tire Model, Tire Size, DOT/TIN)',
    content: [
      {
        type: EContentTypes.ShortDescription,
        text: 'Anyline Tire Sidewall captures DOT/TIN, Tire Size, and Tire Make & Model from any passenger tire sidewall by taking a single photo from any mobile device.',
      },
      {
        type: EContentTypes.Benefits,
        text: [
          'Reduce manual entry mistakes',
          'Reduce tire inspection time by 413%',
          'Sell more tires by working faster',
          'Improve tire inventory management',
          'Prevent tire accidents and stay compliant',
        ],
      },
      {
        type: EContentTypes.Features,
        text: [
          'Retail Tire Services',
          'Automotive Retail',
          'Tire E-commerce',
          'Auto Service & Repair',
          'Fleet management',
        ],
      },
    ],
    scanMode: EScanModeTypes.TSW,
    active: true,
    data: [
      {
        img1: './images/samples/TSW1.jpg',
        img2: './images/samples/TSW2.jpg',
        img3: './images/samples/TSW3.jpg',
      },
    ],
  },
  {
    id: '1',
    preselectName: EScanModePreselectName.AUTO_ANALOG_DIGITAL_METER,
    title: 'Meters',
    useCase: 'for the Energy & Utilities Industry',
    description: '(Analog/Digital)',
    content: [
      {
        type: EContentTypes.ShortDescription,
        text: 'Instantly read analog and digital meters of different utilities like gas, water, and electricity.',
      },
      {
        type: EContentTypes.Benefits,
        text: [
          'Improve quality of data collected',
          'Improve workforce efficiency',
          'Improve customer experience',
          'Reduce overheads',
        ],
      },
      {
        type: EContentTypes.Features,
        text: ['Workforce Meter Reading', 'Customer Meter Reading'],
      },
    ],
    scanMode: EScanModeTypes.AUTO_ANALOG_DIGITAL_METER,
    active: true,
    data: [
      {
        img1: './images/samples/Meter1.png',
        img2: './images/samples/Meter2.jpg',
        img3: './images/samples/Meter3.png',
      },
    ],
  },
  {
    id: '2',
    preselectName: EScanModePreselectName.ANALOG_DIGITAL_SNR_BAR,
    title: 'Meters and Meter Identification',
    useCase: 'for the Energy & Utilities Industry',
    description: '(Analog/Digital) and (Barcode/Serial Number)',
    content: [
      {
        type: EContentTypes.ShortDescription,
        text: 'Instantly identify meters via the serial number or barcode, and simultaneously read the analog or digital meter value.',
      },
      {
        type: EContentTypes.Benefits,
        text: [
          'Improve quality of data collected',
          'Improve workforce efficiency',
          'Fight fraud and reduce losses',
          'Improve customer experience',
          'Reduce overheads',
        ],
      },
      {
        type: EContentTypes.Features,
        text: ['Workforce Meter Reading', 'Customer Meter Reading'],
      },
    ],
    scanMode: EScanModeTypes.ANALOG_DIGITAL_SNR_BAR,
    active: true,
    data: [
      {
        img1: './images/samples/Meter+MeterId1.jpg',
        img2: './images/samples/Meter+MeterId2.png',
        img3: './images/samples/Meter+MeterId3.png',
      },
    ],
  },
  {
    id: '3',
    preselectName: EScanModePreselectName.ID,
    title: 'MRZ',
    useCase: 'Instantly capture data from various ID',
    description: '(Passports, Visas,..)',
    content: [
      {
        type: EContentTypes.ShortDescription,
        text: 'Instantly capture data from documents with MRZ such as passports and visas.',
      },
      {
        type: EContentTypes.Benefits,
        text: [
          'Scan IDs in a fast and error-free way',
          'Reduce processing time and serve more customers.',
        ],
      },
      {
        type: EContentTypes.Features,
        text: [
          'Retail Tire Services',
          'Commercial Tire Services',
          'Fleet management',
          'Scan & Go Shopping',
          'Last Mile Delivery',
          'Ticket Inspection',
          'Mobile Policing',
        ],
      },
    ],
    scanMode: EScanModeTypes.ID,
    active: true,
    data: [
      {
        img1: './images/samples/MRZ1.png',
        img2: './images/samples/MRZ2.png',
        img3: './images/samples/MRZ3.png',
      },
    ],
  },
  {
    id: '4',
    preselectName: EScanModePreselectName.UNIVERSAL_ID,
    title: 'Identity Documents',
    useCase: 'Instantly capture data from various ID',
    description: '(ID layouts and MRZ)',
    content: [
      {
        type: EContentTypes.ShortDescription,
        text: 'Instantly capture data from various ID types like identity cards, drivers licenses, and European health insurance cards, as well as all documents with MRZ such as passports and visas.',
      },
      {
        type: EContentTypes.Benefits,
        text: [
          'Scan IDs in a fast and error-free way',
          'Reduce processing time and serve more customers.',
        ],
      },
      {
        type: EContentTypes.Features,
        text: [
          'Retail Tire Services',
          'Commercial Tire Services',
          'Fleet management',
          'Scan & Go Shopping',
          'Last Mile Delivery',
          'Ticket Inspection',
          'Mobile Policing',
        ],
      },
    ],
    scanMode: EScanModeTypes.UNIVERSAL_ID,
    active: true,
    data: [
      {
        img1: './images/samples/ID1.png',
        img2: './images/samples/ID2.png',
        img3: './images/samples/ID3.png',
      },
    ],
  },
  {
    id: '6',
    preselectName: EScanModePreselectName.BARCODE,
    title: 'Barcode',
    useCase: 'Instantly capture barcode information from various objects',
    description: '(1D and 2D symbologies)',
    content: [
      {
        type: EContentTypes.ShortDescription,
        text: 'Enterprise-grade barcode scanner that allows you to instantly capture information from different barcode symbologies on objects like parcels, retail items, tickets, and more!',
      },
      {
        type: EContentTypes.Benefits,
        text: [
          'Reduce costs by replacing dedicated scanning hardware with mobile software',
          'Improve the user experience with mobile scanning',
          'Simultaneously scan multiple barcodes in the same image',
        ],
      },
      {
        type: EContentTypes.Features,
        text: [
          'Tire Retail Services',
          'Workforce Meter Reading',
          'Last Mile Delivery',
          'Scan & Go Shopping',
        ],
      },
    ],
    scanMode: EScanModeTypes.BARCODE,
    active: true,
    data: [
      {
        img1: './images/samples/Barcode1.png',
        img2: './images/samples/Barcode2.png',
        img3: './images/samples/Barcode3.png',
      },
    ],
  },
];

// Hide Meter EMEA behind Feature Flag
if (process.env.REACT_APP_SHOW_METER_EMEA === 'true') {
  const meterEMEAMetaData: IScanModeMetaData = {
    id: '7',
    preselectName: EScanModePreselectName.METER_EMEA,
    title: 'Meter EMEA',
    useCase: 'Doing some nice Meter EMEA',
    description: 'Meter + SNR',
    content: [
      {
        type: EContentTypes.ShortDescription,
        text: 'TODO',
      },
      {
        type: EContentTypes.Benefits,
        text: ['TODO'],
      },
      {
        type: EContentTypes.Features,
        text: ['TODO'],
      },
    ],
    scanMode: EScanModeTypes.METER_EMEA,
    active: true,
    data: [],
  };

  scanModesMetaData.push(meterEMEAMetaData);
}

//  Hide Meter EMEA Barcode behind Feature Flag
if (process.env.REACT_APP_SHOW_METER_EMEA_BARCODE === 'true') {
  const meterEMEABarcodeMetaData: IScanModeMetaData = {
    id: '8',
    preselectName: EScanModePreselectName.METER_EMEA_BARCODE,
    title: 'Meter EMEA Barcode',
    useCase: 'Doing some nice Meter EMEA Barcode',
    description: 'Meter + SNR + Barcode',
    content: [
      {
        type: EContentTypes.ShortDescription,
        text: 'TODO',
      },
      {
        type: EContentTypes.Benefits,
        text: ['TODO'],
      },
      {
        type: EContentTypes.Features,
        text: ['TODO'],
      },
    ],
    scanMode: EScanModeTypes.METER_EMEA_BARCODE,
    active: true,
    data: [],
  };

  scanModesMetaData.push(meterEMEABarcodeMetaData);
}

export default scanModesMetaData;
